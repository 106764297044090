import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import he from 'he'

import SEO from '../components/seo'

class ProjectTemplate extends Component {

  state = {
    position: 0,
    height: 100,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    if (document.documentElement.clientWidth > 900) {
      let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      let element = document.querySelector('.project__banner').offsetTop
      let parallaxHeight = 200 - (475 / document.documentElement.clientWidth * 100)
      currentScroll = (((element - window.innerHeight) / currentScroll) * 100) - 100
      this.setState({
        position: currentScroll,
        height: parallaxHeight,
      })
    }
  }

  renderImage(image, style = {}) {
    let src = image.source_url
    if (image && image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.original)
      src = image.localFile.childImageSharp.original.src
    return <img style={style} src={src} alt={image.alt_text} />
  }

  render() {
    let { project, allWordpressWpProject } = this.props.data

    return (
      <>
        <SEO title={he.decode(project.yoast_meta.yoast_wpseo_title)} bodyClass="project" description={project.yoast_meta.yoast_wpseo_metadesc} />
        <section className='project__splash' style={{backgroundColor: project.acf.color}}>
          <div className='project__splash__inner'>
            <h2>{ project.acf.subtitle }</h2>
            <h1>{ project.title } | { project.acf.category }</h1>
          </div>
          <Fade bottom distance='40px'>
            <picture>{ this.renderImage(project.acf.feature_image) }</picture>
          </Fade>
        </section>

        <section className='project__intro'>
          <Fade bottom distance='40px'>
            <div className='project__intro__inner'>
              <div dangerouslySetInnerHTML={{ __html: project.acf.introduction }} />
            </div>
          </Fade>
        </section>

        <section className='project__banner'>
          <picture>
            { this.renderImage(project.acf.banner_image, { transform: `translateY(${this.state.position}%)`, height: `${this.state.height}%` }) }
          </picture>
        </section>

        <section className='project__pane'>
          <div className='project__pane__inner'>
            <div className='project__pane__content' style={{backgroundColor: project.acf.color_block.color}}>
              <div dangerouslySetInnerHTML={{ __html: project.acf.color_block.text }} />
              <Fade bottom distance='40px'>
                <picture>
                  { this.renderImage(project.acf.color_block.image) }
                </picture>
              </Fade>
            </div>
          </div>
        </section>

        <section>
          { project.acf.content_project && project.acf.content_project.map((el, i) => {
            let content
            if (el.__typename === 'WordPressAcf_table') {
              content = (
                <div className='project__content__table'>
                  <h3>{ el.title }</h3>
                  <div className=''>
                    { el.rows.map((row, j) => (
                      <div key={j} className='project__content__table__row'>
                        <p>{ row.column_1 }</p>
                        <p>{ row.column_2 }</p>
                      </div>
                    )) }
                  </div>
                </div>
              )
            } else if (el.__typename === 'WordPressAcf_quote') {
              content = (
                <div className='project__content__quote'>
                  <p>“{ el.quote }”</p>
                  <h5>{ el.attribution }</h5>
                </div>
              )
            }

            return (
              <div className='project__content' key={i}>
                <picture>
                  { this.renderImage(el.image) }
                </picture>
                { content }
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            )
          }) }
        </section>

        { project.acf.footer.text &&
          <section className='project__footer' style={{backgroundColor: project.acf.color}}>
            <div className='project__footer__inner'>
              <div dangerouslySetInnerHTML={{ __html: project.acf.footer.text }} />
              <div>
                <picture>{ this.renderImage(project.acf.footer.image) }</picture>
              </div>
            </div>
          </section>
        }

        { project.acf.gallery &&
          <section className='project__gallery'>
            <div className='project__gallery__inner'>
              { project.acf.gallery && project.acf.gallery.map((el, i) => {
                return (
                  <picture className='project__gallery-item' key={i}>
                    <Fade bottom distance='40px'>
                      { this.renderImage(el) }
                    </Fade>
                  </picture>
                )
              }) }
            </div>
          </section>
        }

        <div className='footer__news footer__news--projects'>
          <Fade bottom distance='40px' cascade={true}>
            <div className='footer__news__inner'>
              { allWordpressWpProject.edges.map((edge, i) => (
                <Link to={`/projects/${edge.node.slug}`} key={i}>
                  <picture>
                    <h4>{ edge.node.title }</h4>
                    { this.renderImage(edge.node.acf.feature_image) }
                  </picture>
                </Link>
              )) }
            </div>
          </Fade>
        </div>
      </>
    )
  }
}

export default ProjectTemplate

export const query = graphql`
  query ($id: String!) {

    allWordpressWpProject(sort: {fields: [date], order: DESC}, filter:{wordpress_id:{ne:321},id:{ne:$id}}, limit: 3)  {
      edges {
        node {
          title
          slug
          acf {
            feature_image {
              ...original
            }
          }
        }
      }
    }

    project: wordpressWpProject(id: {eq: $id}) {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      id
      slug
      title
      acf {
        color
        subtitle
        category
        introduction
        color_block {
          text
          color
          image {
            ...original
          }
        }
        footer {
          text
          image {
            ...original
          }
        }
        feature_image {
          ...original
        }
        banner_image {
          ...original
        }
        gallery {
          ...original
        }
        content_project {
          __typename
          ... on WordPressAcf_quote {
            quote
            attribution
            image {
              ...original
            }
          }
          ... on WordPressAcf_table {
            title
            rows {
              column_1
              column_2
            }
            image {
              ...original
            }
          }
        }
      }
    }
  }
`
